import enUser1 from "../../Assets/Images/enUser1.png";
import enUser2 from "../../Assets/Images/enUser2.png";
import enUser3 from "../../Assets/Images/enUser3.png";
import enUser4 from "../../Assets/Images/enUser4.png";
import enUser5 from "../../Assets/Images/enUser5.png";
import enUser6 from "../../Assets/Images/enUser6.png";

export const ReportsApproved = [
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Approved",
    Report: "Download",
    Action: "View",
  },
];

export const ReportsPending = [
  {
    data: "31-04-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-07-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-04-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-07-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Pending",
    Report: "Download",
    Action: "View",
  },
];
export const ReportsDecline = [
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-08-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-08-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-02-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
  {
    data: "31-05-2024",
    report: "in/kol/0001",
    ClientName: "Nagaland Science Centre",
    ServiceType: "Maintanance",
    Status: "Decline",
    Report: "Download",
    Action: "View",
  },
];

export const engineersData = [
  {
    img: enUser1,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
  {
    img: enUser2,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
  {
    img: enUser3,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
  {
    img: enUser4,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
  {
    img: enUser5,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
  {
    img: enUser6,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
  {
    img: enUser1,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
  {
    img: enUser2,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
  {
    img: enUser3,
    name: "Sabyasachi Mukharjee",
    phone: "+91987654321",
    email: "smukharjee55@gmail.com",
    address: "KSSTM,Vikas Bhavan PO. Kolkata 700089",
  },
];

export const countries = [
  "Afghanistan",
  "Australia",
  "Bangladesh",
  "Belize",
  "Bhutan",
  "Cambodia",
  "Canada",
  "China",
  "France",
  "Germany",
  "Greece",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
];

export const india_states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman & Nicobar",
  "Chandigarh",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];

export const west_bengal_cities = [
  "Kolkata",
  "Asansol",
  "Siliguri",
  "Durgapur",
  "Bardhaman",
  "Malda",
  "Baharampur",
  "Habra",
  "Kharagpur",
  "Shantipur",
  "Dankuni",
  "Dhulian",
  "Ranaghat",
  "Haldia",
  "Raiganj",
  "Krishnanagar",
  "Nabadwip",
  "Medinipur",
  "Jalpaiguri",
  "Balurghat",
  "Basirhat",
  "Bankura",
  "Chinsurah",
  "Alipurduar",
  "Purulia",
  "Bangaon",
  "Cooch Behar",
  "Katwa",
  "Santipur",
  "Kaliaganj",
  "Jangipur",
  "Murshidabad",
  "Tamluk",
  "Gangarampur",
  "Rampurhat",
  "Bishnupur",
];

export const bellDropItem = [
  "AYAN PAL has Created a report",
  "shakil ahmad has Created a report",
  "sunil kumar has Created a report",
  "shispal has Created a report",
  "raju has Created a report",
  "bhuri singh has Created a report",
  "bhagvaan singh has Created a report",
  "manish bansal has Created a report",
  "surender singh has Created a report",
  "vikram bahadur has Created a report",
  "sunder jha has Created a report",
  "bhupender singh has Created a report",
  "sanjay has Created a report",
  "vikram singh has Created a report",
  "shiv prakash has Created a report",
  "ramdin verma has Created a report",
  "Christopher has Created a report",
  "Samantha has Created a report",
  "Ryan has Created a report",
  "Piper has Created a report",
];

export const allCuntryTabelData = [
  "Canada",
  "China",
  "France",
  "Germany",
  "Greece",
  "India",
];
export const allStateTabelData = [
  {
    country: "India",
    state: "Tamil Nadu",
  },
  {
    country: "India",
    state: "Karnatak",
  },
  {
    country: "India",
    state: "Maharashtra",
  },
  {
    country: "India",
    state: "West Bengal",
  },
  {
    country: "India",
    state: "Kerala",
  },
  {
    country: "India",
    state: "Kerala",
  },
  {
    country: "Iran",
    state: "Nishadur",
  },
  {
    country: "India",
    state: "Bihar",
  },
];
export const allCityTabelData = [
  {
    country: "India",
    state: "Tamil Nadu",
    city: "Chennai",
  },
  {
    country: "India",
    state: "Karnatak",
    city: "Bangalore",
  },
  {
    country: "India",
    state: "Maharashtra",
    city: "Nagpur",
  },
  {
    country: "India",
    state: "Jharkhand",
    city: "Ranchi",
  },
  {
    country: "India",
    state: "Kerala",
    city: "Kozhilkode",
  },

  {
    country: "Iran",
    state: "Nishadur",
    city: "Ranchi",
  },
  {
    country: "India",
    state: "Bihar",
    city: "Patna",
  },
  {
    country: "India",
    state: "Kerala",
    city: "Kozhilkode",
  },
];
